@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;0,700;0,800;1,300;1,400;1,600;1,700;1,800&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}
:root{
  --font-main:'Poppins', sans-serif;
  --font-text:'Open Sans', sans-serif;
}
html,
body {
  font-family: 'Open Sans', sans-serif, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-family: var(--font-text), -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}
body{
  background-image: url('https://i.imgur.com/W2FsztU.png');
  background-size: cover;
}
.section{
    background: url('https://i.imgur.com/3XjJGte.png');
}
.header {
    position: absolute;
    top: 0px;
    z-index: 999;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px;
}
.header2 {
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 999;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px;
    background: rgba(251, 251, 252);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.08);
    transition: transform 6s ease;
    animation: myanimation 3s;
}

@keyframes myanimation {
    0% {
        background: rgba(251, 251, 252,);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: transparent;
    }
    35% {
        background: rgba(251, 251, 252,);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: black;
    }
    100% {
        background: rgba(251, 251, 252);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: black;
    }
}

@media screen and (max-width: 900px) {
    .header {
        padding: 12px 5px;
    }
    .header2 {
        padding: 12px 5px;
    }
}

body{
    background: url('https://i.imgur.com/3XjJGte.png');
}
