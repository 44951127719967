.header {
    position: absolute;
    top: 0px;
    z-index: 999;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px;
}
.header2 {
    position: sticky;
    top: 0px;
    z-index: 999;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 30px;
    background: rgba(251, 251, 252);
    box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.08);
    transition: transform 6s ease;
    animation: myanimation 3s;
}

@keyframes myanimation {
    0% {
        background: rgba(251, 251, 252,);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: transparent;
    }
    35% {
        background: rgba(251, 251, 252,);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: black;
    }
    100% {
        background: rgba(251, 251, 252);
        box-shadow: 4px 4px 24px rgba(25, 25, 25, 0.2);
        color: black;
    }
}

@media screen and (max-width: 900px) {
    .header {
        padding: 12px 5px;
    }
    .header2 {
        padding: 12px 5px;
    }
}
